<template>
  <div>
    <div class="container mt-4 mb-7 mt-md-12 mb-md-15 py-0">
      <b-breadcrumb class="mb-2 mb-md-3 p-0" style="padding:0!important">
        <b-breadcrumb-item :to="{ name: 'HelpCenter' }">
          {{ $t("Footer.HelpCenter") }}
        </b-breadcrumb-item>
        <b-breadcrumb-item active class="text-capitalize">{{
          $route.params.subsection.replaceAll("-", " ") ||
            $route.params.subsection
        }}</b-breadcrumb-item>
      </b-breadcrumb>
      <Title
        :title="
          `${$t('Footer.HelpCenter')} ${ $t('HelpCenter.ForSection', [
            $route.params.subsection === 'buyers' ||
            $route.params.subsection === 'acheteurs'
              ? ' ' + $t('Footer.Footer.Buyers').toLowerCase() :
              $route.params.subsection === 'sellers' ||
              $route.params.subsection === 'vendeurs' 
              ? ' ' + $t('Footer.Footer.Seelers').toLowerCase() :
              $route.params.subsection === 'vehicle-inspection-agents' ||
              $route.params.subsection === 'agents-d\'inspection-des-véhicules' 
              ? ' ' + $t('WorkWithUs.InspectionAgents').toLowerCase() :
              $route.params.subsection === 'customs-brokers' ||
              $route.params.subsection === 'courtiers-en-douane' 
              ? ' ' + $t('WorkWithUs.CustomsBrokers').toLowerCase() :
              $route.params.subsection === 'sales-representatives' ||
              $route.params.subsection === 'représentant-des-ventes' 
              ? ' ' + $t('navBar.label.salesRepresentatives').toLowerCase() :
              $route.params.subsection === 'freight-forwarders' ||
              $route.params.subsection === 'transitaires' 
              ? ' ' + $t('WorkWithUs.FrightForwarders').toLowerCase()
              : ''
          ])}`
        "
      />
      <div class="mt-5 mt-md-6 mt-lg-8">
        <ContainerInfoCards :cards="cards" :sectionTitle="$t('HelpCenter.InterestedIn')"/>
      </div>
        <MoreHelp />
    </div>
  </div>
</template>

<script>
import ContainerInfoCards from "@/components/global/containers/ContainerInfoCards.vue";
import Title from "@/components/global/headings/Title.vue";
import MoreHelp from "@/components/global/MoreHelp.vue";
import helpBuyersLinks from "@/components/mixins/helpBuyersLinks.js";

export default {
  components: {
    ContainerInfoCards,
    Title,
    MoreHelp,
  },
  mixins: [helpBuyersLinks],
  data() {
    return {
      cards: null,
    };
  },
  beforeMount() {
    const subsection = this.$route.params.subsection;
    const capitalizedSubsection = subsection.charAt(0).toUpperCase() + subsection.slice(1)
    document.title = `${capitalizedSubsection.replaceAll("-", " ")} | ${
      document.title
    }`;
    if (
      subsection ===
      this.$t("Footer.Footer.Buyers").toLowerCase()
    ) {
      this.cards = this.links;
    }
  },
};
</script>

<style></style>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"container mt-4 mb-7 mt-md-12 mb-md-15 py-0"},[_c('b-breadcrumb',{staticClass:"mb-2 mb-md-3 p-0",staticStyle:{"padding":"0!important"}},[_c('b-breadcrumb-item',{attrs:{"to":{ name: 'HelpCenter' }}},[_vm._v(" "+_vm._s(_vm.$t("Footer.HelpCenter"))+" ")]),_c('b-breadcrumb-item',{staticClass:"text-capitalize",attrs:{"active":""}},[_vm._v(_vm._s(_vm.$route.params.subsection.replaceAll("-", " ") || _vm.$route.params.subsection))])],1),_c('Title',{attrs:{"title":`${_vm.$t('Footer.HelpCenter')} ${ _vm.$t('HelpCenter.ForSection', [
          _vm.$route.params.subsection === 'buyers' ||
          _vm.$route.params.subsection === 'acheteurs'
            ? ' ' + _vm.$t('Footer.Footer.Buyers').toLowerCase() :
            _vm.$route.params.subsection === 'sellers' ||
            _vm.$route.params.subsection === 'vendeurs' 
            ? ' ' + _vm.$t('Footer.Footer.Seelers').toLowerCase() :
            _vm.$route.params.subsection === 'vehicle-inspection-agents' ||
            _vm.$route.params.subsection === 'agents-d\'inspection-des-véhicules' 
            ? ' ' + _vm.$t('WorkWithUs.InspectionAgents').toLowerCase() :
            _vm.$route.params.subsection === 'customs-brokers' ||
            _vm.$route.params.subsection === 'courtiers-en-douane' 
            ? ' ' + _vm.$t('WorkWithUs.CustomsBrokers').toLowerCase() :
            _vm.$route.params.subsection === 'sales-representatives' ||
            _vm.$route.params.subsection === 'représentant-des-ventes' 
            ? ' ' + _vm.$t('navBar.label.salesRepresentatives').toLowerCase() :
            _vm.$route.params.subsection === 'freight-forwarders' ||
            _vm.$route.params.subsection === 'transitaires' 
            ? ' ' + _vm.$t('WorkWithUs.FrightForwarders').toLowerCase()
            : ''
        ])}`}}),_c('div',{staticClass:"mt-5 mt-md-6 mt-lg-8"},[_c('ContainerInfoCards',{attrs:{"cards":_vm.cards,"sectionTitle":_vm.$t('HelpCenter.InterestedIn')}})],1),_c('MoreHelp')],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }